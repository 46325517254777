import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  NoneVulnerabilityIcon,
  LowVulnerabilityIcon,
  MediumVulnerabilityIcon,
  HighVulnerabilityIcon,
  CriticalVulnerabilityIcon,
  NoneVulnerabilityChip,
  LowVulnerabilityChip,
  MediumVulnerabilityChip,
  HighVulnerabilityChip,
  CriticalVulnerabilityChip,
  UnverifiedSignatureIcon,
  VerifiedSignatureIcon,
  UnknownVulnerabilityIcon,
  UnknownVulnerabilityChip,
  FailedScanIcon,
  FailedScanChip,
  NotTrustedSignatureIcon
} from './vulnerabilityAndSignatureComponents';

const getStrongestSignature = (signatureInfo) => {
  if (isEmpty(signatureInfo)) return null;
  const trusted = signatureInfo.find((si) => si.isTrusted);
  if (!isEmpty(trusted)) return trusted;
  return signatureInfo[0];
};

const getAllAuthorsOfSignatures = (signatureInfo) => {
  if (isEmpty(signatureInfo)) return '';
  const signatureAuthors = signatureInfo.filter((si) => si.isTrusted).map((si) => si.author);
  return signatureAuthors.join(',');
};

const VulnerabilityIconCheck = ({ vulnerabilitySeverity }) => {
  let result;

  const { t } = useTranslation();

  let vulnerabilityStringTitle = '';
  if (vulnerabilitySeverity) {
    vulnerabilityStringTitle = vulnerabilitySeverity.charAt(0) + vulnerabilitySeverity.substring(1).toLowerCase();
    switch (vulnerabilityStringTitle) {
      case 'None':
        vulnerabilityStringTitle = 'vulnerabilityCountCard.no';
        break;
      case 'Low':
        vulnerabilityStringTitle = 'vulnerabilityCountCard.low';
        break;
      case 'Medium':
        vulnerabilityStringTitle = 'vulnerabilityCountCard.medium';
        break;
      case 'High':
        vulnerabilityStringTitle = 'vulnerabilityCountCard.high';
        break;
      case 'Critical':
        vulnerabilityStringTitle = 'vulnerabilityCountCard.critical';
        break;
      case 'Unknown':
        vulnerabilityStringTitle = 'vulnerabilityCountCard.unknown';
        break;
    }
    vulnerabilityStringTitle = t(vulnerabilityStringTitle);
  }
  switch (vulnerabilitySeverity) {
    case 'NONE':
      result = <NoneVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'LOW':
      result = <LowVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'MEDIUM':
      result = <MediumVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'HIGH':
      result = <HighVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'CRITICAL':
      result = <CriticalVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'UNKNOWN':
      result = <UnknownVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case '':
      result = <FailedScanIcon />;
      break;
    default:
      result = <></>;
  }
  return result;
};

const VulnerabilityChipCheck = ({ vulnerabilitySeverity }) => {
  let result;
  switch (vulnerabilitySeverity) {
    case 'NONE':
      result = <NoneVulnerabilityChip />;
      break;
    case 'LOW':
      result = <LowVulnerabilityChip />;
      break;
    case 'MEDIUM':
      result = <MediumVulnerabilityChip />;
      break;
    case 'HIGH':
      result = <HighVulnerabilityChip />;
      break;
    case 'CRITICAL':
      result = <CriticalVulnerabilityChip />;
      break;
    case 'UNKNOWN':
      result = <UnknownVulnerabilityChip />;
      break;
    case '':
      result = <FailedScanChip />;
      break;
    default:
      result = <></>;
  }
  return result;
};

const SignatureIconCheck = ({ signatureInfo }) => {
  const strongestSignature = getStrongestSignature(signatureInfo);
  if (strongestSignature === null) return <UnverifiedSignatureIcon signatureInfo={signatureInfo} />;
  if (strongestSignature.isTrusted) return <VerifiedSignatureIcon signatureInfo={signatureInfo} />;
  return <NotTrustedSignatureIcon signatureInfo={signatureInfo} />;
};

export {
  VulnerabilityIconCheck,
  VulnerabilityChipCheck,
  SignatureIconCheck,
  getStrongestSignature,
  getAllAuthorsOfSignatures
};
