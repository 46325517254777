import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  cvePackageCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0rem 0.3125rem 0.625rem rgba(131, 131, 131, 0.08)',
    border: '1px solid #E0E5EB',
    borderRadius: '0.75rem',
    flex: 'none',
    alignSelf: 'stretch',
    width: '100%'
  },
  cveInfo: {
    marginTop: '2%'
  },
  vulnerabilityCardDivider: {
    margin: '1rem 1rem'
  }
}));

function VulnerabilityPackageSection(props) {
  const { cve } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      spacing="0.2rem"
      sx={{ width: '100%', padding: '0.2rem 0.5rem' }}
      data-testid="cve-package-section"
    >
      <Typography variant="overline" color="primary" data-testid="cve-info-pkg-name" sx={{ fontWeight: 'bold' }}>
        {cve.packageName}
      </Typography>
      <Typography variant="body2" className={classes.cveInfo}>
        {t('vulnerabilityPackageSection.packagePath')}
      </Typography>
      <Typography variant="body1" color="primary" data-testid="cve-info-pkg-path">
        {cve.packagePath}
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.cveInfo}>
            {t('vulnerabilityPackageSection.installedVersion')}
          </Typography>
          <Typography variant="body1" color="primary" data-testid="cve-info-pkg-install-ver">
            {cve.packageInstalledVersion}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.cveInfo}>
            {t('vulnerabilityPackageSection.fixedVersion')}
          </Typography>
          <Typography variant="body1" color="primary" data-testid="cve-info-pkg-fixed-ver">
            {cve.packageFixedVersion}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.vulnerabilityCardDivider} />
    </Stack>
  );
}

export default VulnerabilityPackageSection;
