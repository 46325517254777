import React from 'react';
import { useTranslation } from 'react-i18next';

import makeStyles from '@mui/styles/makeStyles';
import { Stack, Tooltip } from '@mui/material';

const criticalColor = '#ff5c74';
const criticalBorderColor = '#f9546d';

const highColor = '#ff6840';
const highBorderColor = '#ee6b49';

const mediumColor = '#ffa052';
const mediumBorderColor = '#f19d5b';

const lowColor = '#f9f486';
const lowBorderColor = '#f0ed94';

const unknownColor = '#f2ffdd';
const unknownBorderColor = '#e9f4d7';

const totalBorderColor = '#e0e5eb';

const fontSize = '0.75rem';

const useStyles = makeStyles((theme) => ({
  cveCountCard: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    color: theme.palette.primary.main,
    fontSize: fontSize,
    fontWeight: '600',
    borderRadius: '3px',
    marginBottom: '0',
    cursor: 'pointer'
  },
  totalSeverity: {
    border: '1px solid ' + totalBorderColor
  },
  severityList: {
    fontSize: fontSize,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5em'
  },
  criticalSeverity: {
    backgroundColor: criticalColor,
    border: '1px solid ' + criticalBorderColor
  },
  highSeverity: {
    backgroundColor: highColor,
    border: '1px solid ' + highBorderColor
  },
  mediumSeverity: {
    backgroundColor: mediumColor,
    border: '1px solid ' + mediumBorderColor
  },
  lowSeverity: {
    backgroundColor: lowColor,
    border: '1px solid ' + lowBorderColor
  },
  unknownSeverity: {
    backgroundColor: unknownColor,
    border: '1px solid ' + unknownBorderColor
  }
}));

function VulnerabilitiyCountCard(props) {
  const classes = useStyles();
  const { total, critical, high, medium, low, unknown, filterBySeverity } = props;

  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing="0.5em">
      <Tooltip title={t('vulnerabilityCountCard.total')} onClick={() => filterBySeverity('')}>
        <div className={[classes.cveCountCard, classes.totalSeverity].join(' ')}>
          {t('vulnerabilityCountCard.total')} {total}
        </div>
      </Tooltip>
      <div className={classes.severityList}>
        <Tooltip title={t('vulnerabilityCountCard.critical')} onClick={() => filterBySeverity('CRITICAL')}>
          <div className={[classes.cveCountCard, classes.criticalSeverity].join(' ')}>
            {t('vulnerabilityCountCard.criticalShort')} {critical}
          </div>
        </Tooltip>
        <Tooltip title={t('vulnerabilityCountCard.high')} onClick={() => filterBySeverity('HIGH')}>
          <div className={[classes.cveCountCard, classes.highSeverity].join(' ')}>
            {t('vulnerabilityCountCard.highShort')} {high}
          </div>
        </Tooltip>
        <Tooltip title={t('vulnerabilityCountCard.medium')} onClick={() => filterBySeverity('MEDIUM')}>
          <div className={[classes.cveCountCard, classes.mediumSeverity].join(' ')}>
            {t('vulnerabilityCountCard.mediumShort')} {medium}
          </div>
        </Tooltip>
        <Tooltip title={t('vulnerabilityCountCard.low')} onClick={() => filterBySeverity('LOW')}>
          <div className={[classes.cveCountCard, classes.lowSeverity].join(' ')}>
            {t('vulnerabilityCountCard.lowShort')} {low}
          </div>
        </Tooltip>
        <Tooltip title={t('main.unknown')} onClick={() => filterBySeverity('UNKNOWN')}>
          <div className={[classes.cveCountCard, classes.unknownSeverity].join(' ')}>
            {t('vulnerabilityCountCard.unknownShort')} {unknown}
          </div>
        </Tooltip>
      </div>
    </Stack>
  );
}

export default VulnerabilitiyCountCard;
